<template>
  <v-card flat class="pa-3 mt-2">
    <!-- <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="accountDataLocale.avatarImg"></v-img>
      </v-avatar>
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <input
          ref="refInputEl"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Reset
        </v-btn>
        <p class="text-sm mt-5">
          Allowed JPG, GIF or PNG. Max size of 800K
        </p>
      </div>
    </v-card-text> -->

    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field readonly v-model="item.username" label="Username" dense outlined></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field v-model="item.name" label="Nama" dense outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="item.email" label="E-mail" dense outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field readonly v-model="item.role" dense label="Role" outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <legend>Ubah Password</legend>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="password_lama" dense type="password" label="Password Lama" outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="6"> </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="password" dense type="password" label="Password Baru" outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="password_ulang"
              dense
              type="password"
              label="Ulangi Password Baru"
              outlined
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="12">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Your email is not confirmed. Please check your inbox.
                  </p>
                  <a
                    href="javascript:void(0)"
                    class="text-decoration-none warning--text"
                  >
                    <span class="text-sm">Resend Confirmation</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col> -->

          <v-col cols="12">
            <v-btn color="primary" class="" @click="save"> Simpan </v-btn>
            <!-- <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              Cancel
            </v-btn> -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'

import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  // setup(props) {
  //   const status = ['Active', 'Inactive', 'Pending', 'Closed']

  //   const accountDataLocale = ref(JSON.parse(JSON.stringify(props.accountData)))

  //   const resetForm = () => {
  //     accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
  //   }

  //   return {
  //     status,
  //     accountDataLocale,
  //     resetForm,
  //     icons: {
  //       mdiAlertOutline,
  //       mdiCloudUploadOutline,
  //     },
  //   }
  // },
  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  data: () => ({
    item: {},
    password_lama: '',
    password: '',
    password_ulang: '',
  }),
  created() {
    this.getDataFromApi()
  },
  methods: {
    // initialize() {
    // },
    getDataFromApi() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      // form.append('search', this.search)
      axios
        .post(`${apiRoot}/api/User/getUser`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.item = response.data.data
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    save() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('username', this.item.username)
      form.append('name', this.item.name)
      form.append('email', this.item.email)
      form.append('role', this.item.role)
      form.append('password_lama', this.password_lama)
      form.append('password', this.password)
      form.append('password_ulang', this.password_ulang)
      axios
        .post(`${apiRoot}/api/User/update_self`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            alert(response.data.message)
            if (this.password === '' || this.password === null) {
              this.password_lama = ''
              this.password = ''
              this.password_ulang = ''
              this.getDataFromApi()
            } else {
              this.$store.dispatch('logout', '')
            }
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
  },
}
</script>
